@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Sacramento&display=swap');

.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.header {
  padding-top: 2rem;
}

h4 {
  margin-top:0 ;
  margin-bottom: 1rem;
}

.modal {
  height: 75vh;
  width: 75vw;
  font-family: 'Inter', sans-serif;
  text-align: justify;
  z-index: 12;

}

.modal ul {
  padding: 0;
  padding-left: 7%;
}

.modal ul li {
  padding-top: 4%;
}

.modal-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.modal-table {
  padding-bottom: 10%;
}

th {
  border-bottom: #282c34 3px solid;
}

tr{
  border-bottom: #282c34 3px solid;
}

.amount-disclaimer {
  font-size: 0.8rem;
}

.celo-logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 10rem;
}

.symbol {
  width: 01.3rem;
}

.symbol-hover {
  width: 0.7rem;
}

.info {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  color: white;
  background-color: #282c34;
  border: none;
  padding : 1rem;
}

.github {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  font-size: 1rem;
  color: white;
  background-color: #282c34;
  border: none;
  z-index: 1;

}

.github:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.info:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.legend-right {
    text-align: right;
    position: fixed;
    flex: auto;
    font-size: 1rem;
    bottom: 0;
    right: 0; 
    padding: 3rem;
}

.legend-left {
  text-align: left;
  position: fixed;
  flex: auto;
  font-size: 1rem;
  bottom: 0;
  left: 0; 
  padding: 3rem;
}

.w3text{
  font-family: 'Sacramento', cursive;
  font-size: 2rem;
}

.footer-container{
  background-color: transparent;
  color: white;
  text-align: center;
  position: fixed;
  bottom: 1px;
  left: 50%;
  transform: translate(-50%, 0);
}

.footer{
  text-decoration: none;
  color: white;
  font-size: 0.8rem;
}

.donate {
  font-size: 0.5rem;
}

.TPT {
  text-decoration: none;
  color: #FCFF52;
}

.dollars {
  color: #FCFF52;
}

.TPT:hover {
  cursor: hover;
}

.pie-chart {
  width: 65vw;
}

.chart-text {
  background-color: #282c34;
  color: white;
  border: none;
  font-size: 1rem;
  border-radius: 5px;
}

.chart-button:hover {
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: #fff
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  font-size: 0.7rem;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 3px;
  text-decoration: none;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.toggle {
  z-index: 0;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  transition: all 5s ease-in;
  top: -5px;
  left: 80%;
  
  
}

.tooltip:hover {
  transform: scale(1.05);
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media (max-width: 500px) {

  .modal {
    font-size: 0.8rem;
  }

  .celo-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 5rem;
  }

  .symbol {
    width: 0.8rem;
  }

  .legend-left {
    position: fixed;
    font-size: 0.7rem;
    bottom: 0;
    left: 0; 
    padding-bottom: 4rem;
    padding-left: 1rem;
}

.legend-right {
  position: fixed;
  font-size: 0.7rem;
  bottom: 0;
  right: 0; 
  padding-bottom: 4rem;
  padding-right: 1rem;
}


.footer{
          font-size: 0.5rem;
}

.donate {
  font-size: 0.4rem;
}

.w3text {
  font-size: 1.5rem;
}

}

@media screen and (min-width: 980px) {
  .pie-chart {
    width: 35vw;
  }
}
